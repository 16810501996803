import {useState, useMemo} from 'react';
import { useNavigate } from 'react-router-dom';
import { Action } from 'modules/jobs/components/DataTable';
import { Link } from '@mui/material';
import classes from './EmployersScreen.admin.module.css';
import { useEmployers } from 'modules/employers/services/employers.service';
import { Employer } from 'modules/employers/services/employer';
import { BaseEmployersScreen, GridRow } from './BaseEmployersScreen';


export function EmployersAdminScreen() {
  const { deleteEmployer } = useEmployers();
  const [employers, setEmployers] = useState<Employer[]>();
  const navigate = useNavigate();

  const actions: Action<GridRow>[] = useMemo(() => [
    { label: 'edit', onAction: ({ raw }) => navigate('employers/' + raw.ref.id + '/edit') },
    { label: 'delete', onAction: ({ raw }) => {
      deleteEmployer(raw.ref.id);
      setEmployers(employers?.filter(em => em.ref.id !== raw.ref.id));
    }},
  ], [navigate, deleteEmployer, employers]);

  function handleAddEmployer() {
    navigate('employers/add');
  }

  return (<>
    <BaseEmployersScreen actions={actions}/>

    <Link
      component='button'
      color='mainBackground.contrastText'
      variant='body1'
      underline='none'
      className={classes.addButton}
      onClick={handleAddEmployer}
    >+ Create New Employer</Link>
  </>);
}
