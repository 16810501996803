import { FirebaseApp } from 'firebase/app';
import { Jobtype } from '../gigup';
// import { getFirestore, collection, getDocs } from '@firebase/firestore/lite';


export async function getAllJobtypes(app: FirebaseApp): Promise<Jobtype[]> {
  // const db = getFirestore(app);
  // const jobtypes = (await getDocs(collection(db, 'guRefJobtypes'))).docs;
  // return jobtypes.map(doc => ({ name: doc.get('name') as string, uid: doc.id }));
  return [
    {
      "name": "Store Team Member",
      "uid": "3TX50HsqxbbyZZXAYmyp",
    },
    {
      "name": "Warehouse Support",
      "uid": "47FkPuDhzGNLsVoODxuC",
    },
    {
      "name": "Sales Associate",
      "uid": "6wDoTPKLWBAJ1OSXT73X",
    },
    {
      "name": "Cook",
      "uid": "AJ98Ics6fT47Wbkq3lvu",
    },
    {
      "name": "Host",
      "uid": "BiyDcQlLh4ETmhdJCFM2",
    },
    {
      "name": "Server",
      "uid": "ELAN04EbWBNOniVjAiKv",
    },
    {
      "name": "Cafeteria",
      "uid": "Ie3YDrYP464LS1lkKdRi",
    },
    {
      "name": "Janitor",
      "uid": "N2obnGzGi9SnxFl2gX80",
    },
    {
      "name": "Administrative Assistant",
      "uid": "RKBsmz6VRoDuQG2GvEQa",
    },
    {
      "name": "Security",
      "uid": "UmcDlr1Rkmz4w58W7ZkK",
    },
    {
      "name": "Bartender",
      "uid": "WxQyrTpZxPglQ75kilaw",
    },
    {
      "name": "Table Busser",
      "uid": "ZC55ZzNp9lK74dV0aDNP",
    },
    {
      "name": "Supervisor",
      "uid": "ZRJmlxeNwH5LrvHkyiza",
    },
    {
      "name": "Cashier",
      "uid": "cO1U7h4c6OSQLLQkbxpf",
    },
    {
      "name": "Office",
      "uid": "dmFhz255vqjRd7Jl8Uf5",
    },
    {
      "name": "Housekeeping",
      "uid": "f75XHYVDPe9boDZ5A7DB",
    },
    {
      "name": "General Labor",
      "uid": "kkkotyFUMPBChtQ2fcRj",
    },
    {
      "name": "Front Desk",
      "uid": "odrH6A8xoBPGlxpTPjXd",
    },
    {
      "name": "Kitchen Staff",
      "uid": "pzA93XflbthXOjNzBJnZ",
    },
    {
      "name": "Maintenance",
      "uid": "sEA6tucUsASQ53mEXx92",
    },
    {
      "name": "Store Clerk",
      "uid": "yyHXMVAXmmF0W3mvHjas",
    },
    {
      "name": "Customer Service",
      "uid": "zczcDNE5S6qkC3qXQzfP",
    },
  ]
}
