import { FirebaseApp } from 'firebase/app';
import { Industry } from '../gigup';
// import { getFirestore, collection, getDocs } from '@firebase/firestore/lite';


export async function getAllIndustries(app: FirebaseApp): Promise<Industry[]> {
  // const db = getFirestore(app);
  // const industries = (await getDocs(collection(db, 'guRefIndustries'))).docs;
  // return industries.map(doc => ({ name: doc.get('name') as string, uid: doc.id }));

  return [
    {
      "name": "Gas/Convenience Stores",
      "uid": "88XAlugmeySXx73hkEgH",
    },
    {
      "name": "Non-Clinical Healthcare",
      "uid": "8Lq3SSEYlU1vPn8pEoem",
    },
    {
      "name": "Grocery",
      "uid": "92GxvyDD2ehXGpTfE1w2",
    },
    {
      "name": "Retail",
      "uid": "BVBYhKeLhmGSDwwjw20d",
    },
    {
      "name": "Restaurant/Bar",
      "uid": "UvehVbs40Mnmdw9cMJer",
    },
    {
      "name": "Hospitality",
      "uid": "iS21ul8olgsDe1t8rgPL",
    },
    {
      "name": "Landscaping",
      "uid": "sZq0tNhJpWPqxx9Ixekt",
    },
  ]
}
