import {useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Action, DataTable } from 'modules/jobs/components/DataTable';
import { CircularProgress, Typography } from '@mui/material';
import { useDefinedUser } from 'modules/user/services/user.service';
import classes from './BaseCurrentJobsScreen.module.css';
import { Job } from 'modules/jobs/services/job';
import { useJobs } from 'modules/jobs/services/jobs.service';
import { HeadCell } from 'modules/jobs/components/interfaces';


export interface GridRow {
  raw: Job;
  jobId: string;
  location: string;
  positions: number;
  startDate: string;
  endDate: string;
  payRange: string;
}


const headCells: readonly HeadCell<GridRow, keyof GridRow>[] = [
  {
    id: 'jobId',
    numeric: false,
    label: 'Job ID',
    sortable: true,
    sortValue: row => row.raw.jid
  },
  {
    id: 'location',
    numeric: true,
    label: 'Location',
    sortable: true,
  },
  {
    id: 'positions',
    numeric: true,
    label: 'Positions',
    sortable: true,
  },
  {
    id: 'startDate',
    numeric: true,
    label: 'Start Date',
    sortable: true,
    sortValue: row => +row.raw.firstDay
  },
  {
    id: 'endDate',
    numeric: true,
    label: 'End Date',
    sortable: true,
    sortValue: row => +row.raw.lastDay
  },
  {
    id: 'payRange',
    numeric: true,
    label: 'Pay Range',
  },
];


type Props = {
  actions?: Action<GridRow>[];
}


export function BaseCurrentJobsScreen({ actions }: Props) {
  const { employerId } = useParams<'employerId'>();
  const { getEmployerJobs } = useJobs();
  const { getJobs } = useDefinedUser();
  const [jobs, setJobs] = useState<Job[]>();
  const navigate = useNavigate();
  const [rows, setRows] = useState<GridRow[]>([]);

  useEffect(() => {
    (employerId ? getEmployerJobs(employerId) : getJobs()).then(jobs => setJobs(jobs ?? [])); // if no employer load jobs for logged user employer
  }, [getJobs, getEmployerJobs, employerId]);

  // fill rows for the table
  useEffect(() => {
    jobs && setRows(jobs.map(j => {
      return {
        raw: j,
        jobId: j.jid + ' / ' + j.title,
        location: j.location,
        positions: j.openings,
        startDate: j.firstDay.toLocaleDateString('en-US'),
        endDate: j.lastDay.toLocaleDateString('en-US'),
        payRange: '$' + j.minHourlyWage + '-' + j.maxHourlyWage + '/hr',
      };
    }));
  }, [jobs]);

  function handleJobClick({ raw }: GridRow) {
    navigate('/jobs/' + raw.ref.id);
  }

  return(
    <div className={classes.container}>

      <div className={classes.header}>
        <Typography variant='h4' sx={{color: 'mainBackground.contrastText'}}>Current Openings</Typography>
        { !jobs && <CircularProgress className={classes.progress} size={30}/> }
      </div>

      <DataTable
        data={rows}
        headCells={headCells}
        initialOrderBy='startDate'
        keyField={row => row.jobId}
        onRowClick={handleJobClick}
        actions={actions}
      />
    </div>
  )
}
