import { Box } from '@mui/system';
import { Outlet } from 'react-router-dom';
import { JobPanel } from './components/JobPanel';
import classes from './JobScreen.module.css';


export function JobScreen() {
  return (<>
    <Box className={classes.container} sx={{display: { xs: 'none', md: 'flex', position: 'relative'}}}>
      <div className={classes.job}>
        <JobPanel/>
      </div>

      <div className={classes.content}>
        <Outlet/>
      </div>
    </Box>

    {/* <Box sx={{display: { xs: 'flex', md: 'none', flexDirection: 'column', alignItems: 'center', overflow: 'scroll'}}}>
      <JobPanel/>
      <Outlet/>
    </Box> */}
  </>);
}
