import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router';
import { Layout } from '../components/Layout/Layout';
import { CurrentJobsScreen } from 'modules/jobs/screens/CurrentJobsScreen';
import { JobScreen } from 'modules/jobs/screens/JobScreen';
import { LoginScreen } from 'modules/auth/screens/LoginScreen';
import { ForgotPasswordScreen } from 'modules/auth/screens/ForgotPasswordScreen';
import { useAuth } from 'modules/auth/services/auth.service';
import { RegisterScreen } from 'modules/auth/screens/RegisterScreen';
import { useEffect, useState } from 'react';
import { CandidateScreen } from 'modules/candidate/screens/CandidateScreen';
import { JobCandidatesScreen } from 'modules/jobs/screens/JobCandidatesScreen';
import { useDefinedUser } from 'modules/user/services/user.service';
import { EmployersScreen } from 'modules/employers/screens/EmployersScreen';
import { EmployerEditorScreen } from 'modules/employers/screens/EmployerEditor';
import { JobEditorScreen } from 'modules/jobs/screens/JobEditor';


export function Navigation() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [wasLogged, setWasLogged] = useState(false);

  // navigate to login screen on logout
  useEffect(() => {
    if (!wasLogged && user) setWasLogged(true);
    if (wasLogged && !user) navigate('/login'); // ignore if user was not logged before
  }, [wasLogged, navigate, user]);

  return (
    <Routes>
      <Route path='/' element={<ProtectedRoute><Layout/></ProtectedRoute>}>
        {/* <Route index element={<HomeScreen/>}/> */}
        <Route index element={<RootRoute/>}/>
        <Route path='jobs' element={<CurrentJobsScreen/>}/>
        <Route path='jobs/:jobId' element={<JobScreen/>}>
          <Route index element={<JobCandidatesScreen/>}/>
          <Route path='candidates/:candidateId' element={<CandidateScreen/>}/>
        </Route>

        {/* admin routes */}
        <Route path='employers/add' element={<AdminRoute><EmployerEditorScreen/></AdminRoute>}/>
        <Route path='employers/:employerId/jobs' element={<AdminRoute><CurrentJobsScreen/></AdminRoute>}/>
        <Route path='employers/:employerId/edit' element={<AdminRoute><EmployerEditorScreen/></AdminRoute>}/>
        <Route path='employers/:employerId/jobs/add' element={<AdminRoute><JobEditorScreen/></AdminRoute>}/>
        <Route path='employers/:employerId/jobs/:jobId/edit' element={<AdminRoute><JobEditorScreen/></AdminRoute>}/>
        {/* <Route path='jobs/:jobId/matches' element={<AdminRoute><MatchesEditorScreen/></AdminRoute>}/> */}
      </Route>

      <Route path='/login' element={<LoginScreen/>}/>
      <Route path='/forgotPassword' element={<ForgotPasswordScreen/>}/>
      <Route path='/register' element={<RegisterScreen/>}/>
      <Route path='*' element={<Navigate to='/' />}/>
    </Routes>
  );
}


function ProtectedRoute({ children }: { children: React.ReactElement }) {
  const { user } = useAuth();
  const location = useLocation();

  if (user) return children;

  // Redirect them to the /login page, but save the current location they were
  // trying to go to when they were redirected. This allows us to send them
  // along to that page after they login, which is a nicer user experience
  // than dropping them off on the home page.
  return <Navigate to='/login' state={{ from: location }} replace/>;
}


function AdminRoute({ children }: { children: React.ReactElement }) {
  const { isReady, isAdmin } = useDefinedUser();
  const location = useLocation();

  if (!isReady) return <></>;

  if (isAdmin) return children;

  // Redirect them to the /login page, but save the current location they were
  // trying to go to when they were redirected. This allows us to send them
  // along to that page after they login, which is a nicer user experience
  // than dropping them off on the home page.
  return <Navigate to='/login' state={{ from: location }} replace/>;
}


function RootRoute() {
  const { isAdmin, isReady } = useDefinedUser();

  return !isReady ? <></> : isAdmin ? <EmployersScreen/> : <CurrentJobsScreen/>;
}
