import {useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Action, DataTable } from 'modules/jobs/components/DataTable';
import { CircularProgress, Typography } from '@mui/material';
import classes from './BaseEmployersScreen.module.css';
import { useEmployers } from 'modules/employers/services/employers.service';
import { Employer } from 'modules/employers/services/employer';
import { HeadCell } from 'modules/jobs/components/interfaces';


export interface GridRow {
  raw: Employer;
  name: string;
  location: string;
}


const headCells: readonly HeadCell<GridRow, keyof GridRow>[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Employer',
    sortable: true,
    sortValue: row => row.raw.billingState
  },
  {
    id: 'location',
    numeric: false,
    label: 'Location',
    sortable: true,
  }
];


type Props = {
  actions?: Action<GridRow>[]; // actions are shown in table row 3 dots menu
};


export function BaseEmployersScreen({ actions }: Props) {
  const { getEmployers } = useEmployers();
  const [employers, setEmployers] = useState<Employer[]>();
  const navigate = useNavigate();
  const [rows, setRows] = useState<GridRow[]>([]);

  useEffect(() => {
    getEmployers().then(setEmployers);
  }, [getEmployers]);

  // fill rows for the table
  useEffect(() => {
    employers && setRows(employers.map(employer => {
      return {
        raw: employer,
        name: employer.companyName,
        location: employer.billingCity + ', ' + employer.billingState,
      };
    }));
  }, [employers]);

  function handleClick({ raw }: GridRow) {
    navigate('employers/' + raw.ref.id + '/jobs');
  }

  return(
      <div className={classes.container}>

        <div className={classes.header}>
          <Typography variant='h4' sx={{color: 'mainBackground.contrastText'}}>Employers</Typography>
          { !employers && <CircularProgress className={classes.progress} size={30}/> }
        </div>

        <DataTable
          data={rows}
          headCells={headCells}
          initialOrderBy='name'
          keyField={row => row.name || row.raw.hrEmail}
          onRowClick={handleClick}
          minWidth={400}
          actions={actions}
        />
      </div>
  )
}
