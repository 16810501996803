import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import classes from './Layout.module.css';
import { MainNavigation } from './MainNavigation';
import { Link } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


export function Layout() {
  const navigate = useNavigate();
  const location = useLocation(); // if location.key is 'default' there is no goBack option
  const { palette: { mainBackground } } = useTheme();
  const bgStyle = useMemo(() => ({
    background: `linear-gradient(-45deg, ${mainBackground.color1} 0%, ${mainBackground.color2} 100%)`,
  }), [mainBackground]);

  return(
    <div style={bgStyle} className={classes.container}>
      <MainNavigation/>
      <main className={classes.main}>
        <Outlet/>
        { location.key !== 'default' &&
          <Link
            component='button'
            color='mainBackground.contrastText'
            variant='body1'
            underline='none'
            onClick={() => navigate(-1)}
            className={classes.backButton}
          ><ArrowBackIcon className={classes.backIcon}/></Link>
        }
      </main>
    </div>
  )
}