import * as React from 'react';
import { createContext, useContext, useMemo } from 'react';
import { collection, getDocs, query, CollectionReference, where, getDoc, Timestamp, Firestore } from '@firebase/firestore/lite';
import { useFirebase } from 'modules/shared/services/firebase.service';
import { addDoc, deleteDoc, doc, DocumentReference, setDoc } from 'firebase/firestore/lite';
import { getDocsIn } from 'modules/shared/helpers/firestore.getDocsIn.fn';
import { AddJobDto, Job, JobDto, Match, UpdateJobDto } from './job';
import { useGigup } from 'modules/shared/services/gigup.service';
import { Industry, Jobtype } from 'modules/shared/services/gigup';
import { Candidate } from 'modules/candidate/services/candidate';


type JobsContextType = {
  getJobs: () => Promise<Job[]>;
  getJobById: (id: string) => Promise<Job | undefined>;
  getAllCandidates: () => Promise<Candidate[]>;
  getMatchedCandidates: (jobId: string) => Promise<Candidate[]>;
  getEmployerJobs: (id: string) => Promise<Job[]>;
  addJob: (job: AddJobDto) => Promise<void>;
  updateJob: (id: string, job: UpdateJobDto) => Promise<void>;
  deleteJob: (id: string) => Promise<void>;
};


const JobsContext = createContext<JobsContextType>(null!);


function prepareJob(job: JobDto, ref: DocumentReference<JobDto>, allIndustries: Industry[], allJobtypes: Jobtype[]) {
  return { ...job,
    ref,
    firstDay: job.firstDay.toDate(),
    lastDay: job.lastDay.toDate(),
    dateCreated: job.dateCreated.toDate(),
    industries: allIndustries.filter(i => job.industries.find(ref => ref.id === i.uid)),
    jobtypes: allJobtypes.filter(j => job.jobtypes.find(ref => ref.id === j.uid)),
  };
}

async function getJobById(db: Firestore, id: string, allIndustries: Industry[], allJobtypes: Jobtype[]): Promise<Job | undefined> {
  const jobRef = doc(collection(db, 'guJobs') as CollectionReference<JobDto>, id);
  const job = (await getDoc(jobRef)).data();
  return job && prepareJob(job, jobRef, allIndustries, allJobtypes);
}


async function getJobs(db: Firestore, allIndustries: Industry[], allJobtypes: Jobtype[]): Promise<Job[]> {
  const q = query(
    collection(db, 'guJobs') as CollectionReference<JobDto>,
  );

  return (await getDocs(q)).docs.map(d => prepareJob(d.data(), d.ref, allIndustries, allJobtypes));
}


async function getEmployerJobs(db: Firestore, employerUid: string, allIndustries: Industry[], allJobtypes: Jobtype[]): Promise<Job[]> {
  const employer = doc(db, 'guEmployers/' + employerUid);
  const q = query(
    collection(db, 'guJobs') as CollectionReference<JobDto>,
    where('employer', '==', employer)
  );
  return (await getDocs(q)).docs.map(d => prepareJob(d.data(), d.ref, allIndustries, allJobtypes));

  // console.log(user.uid);

  // let jobList: any[] = [];

  // await Promise.all((await getDocs(q)).docs.map(async (doc) => {
  //   const refStatus = await getDoc(doc.data().status);
  //   const status = refStatus.data()?.name;
  //   jobList.push({...doc.data(), status, jobRef: doc.ref});
  // }));

  // // const matches = await getMatches(app, jobList?.map((job) => job.jobRef) ?? []);
  // // console.log("in get jobs");
  // // console.log(matches?.map((match) => console.log(match.isMatchedByGigup)));

  // return jobList;
}


async function getMatches(db: Firestore, jobs: DocumentReference<JobDto>[]) {
  if(!jobs.length) return [];

  return (await getDocsIn(
    collection(db, 'guMatches') as CollectionReference<Match>,
    'job', jobs)
  ).map(d => d.data());
}


async function getCandidates(db: Firestore, refs: DocumentReference<Candidate>[]) {
  if(!refs.length) return [];

  return (await getDocsIn(
    collection(db, 'guUsers') as CollectionReference<Candidate>,
    'uid', refs.map(r => r.id))
  ).map(d => d.data());
}


async function getAllCandidates(db: Firestore) {
  return (await getDocs(collection(db, 'guUsers') as CollectionReference<Candidate>)).docs.map(user => user.data());
}


async function getMatchedCandidates(db: Firestore, jobId: string) {
  if (jobId) {
    const jobRef = doc(collection(db, 'guJobs') as CollectionReference<JobDto>, jobId);
    return getCandidates(db, (await getMatches(db, [jobRef])).map(m => m.candidate));
  }
  return [];
}


async function addJob(db: Firestore, job: AddJobDto) {
  const { jobtypes, industries, firstDay, lastDay, ...dto } = job;

  const jobsCollection = collection(db, 'guRefJobs') as CollectionReference<Jobtype>;
  const jobtypesRef = jobtypes.map(jobtype => doc(jobsCollection, jobtype.uid));

  const industriesCollection = collection(db, 'guRefJobs') as CollectionReference<Jobtype>;
  const industriesRef = industries.map(industry => doc(industriesCollection, industry.uid));

  await addDoc(collection(db, 'guJobs') as CollectionReference<AddJobDto & { dateCreated: Timestamp }>,
    { ...dto,
      jobtypes: jobtypesRef,
      industries: industriesRef,
      firstDay: Timestamp.fromDate(firstDay),
      lastDay: Timestamp.fromDate(lastDay),
      dateCreated: Timestamp.fromDate(new Date),
    }
  );
}


async function updateJob(db: Firestore, id: string, job: UpdateJobDto) {
  const { jobtypes, industries, firstDay, lastDay, ...dto } = job;

  const jobsCollection = collection(db, 'guRefJobs') as CollectionReference<Jobtype>;
  const jobtypesRef = jobtypes.map(jobtype => doc(jobsCollection, jobtype.uid));

  const industriesCollection = collection(db, 'guRefJobs') as CollectionReference<Jobtype>;
  const industriesRef = industries.map(industry => doc(industriesCollection, industry.uid));

  const ref = doc(collection(db, 'guJobs') as CollectionReference<AddJobDto & { dateCreated: Timestamp }>, id);
  await setDoc(ref, {
    ...dto,
    jobtypes: jobtypesRef,
    industries: industriesRef,
    firstDay: Timestamp.fromDate(firstDay),
    lastDay: Timestamp.fromDate(lastDay),
    dateCreated: Timestamp.fromDate(new Date),
  }, { merge: true });
}


async function deleteJob(db: Firestore, id: string) {
  await deleteDoc(doc(db, 'guJobs', id)); // delete job doc
}


export function JobsProvider({ children }: { children: React.ReactNode }) {
  const { firebaseApp, db } = useFirebase();
  const { allJobtypes, allIndustries } = useGigup();

  const context = useMemo<JobsContextType>(() => ({
    getJobs: () => getJobs(db, allIndustries, allJobtypes),
    getEmployerJobs: (id) => getEmployerJobs(db, id, allIndustries, allJobtypes),
    getJobById: async (id) => getJobById(db, id, allIndustries, allJobtypes),
    getAllCandidates: async () => getAllCandidates(db),
    getMatchedCandidates: async (jobId) => getMatchedCandidates(db, jobId),
    addJob: async (job) => addJob(db, job),
    updateJob: async (id, job) => updateJob(db, id, job),
    deleteJob: async (id) => deleteJob(db, id),
  }), [firebaseApp, allJobtypes, allIndustries]);

  return <JobsContext.Provider value={context}>{children}</JobsContext.Provider>;
}


export function useJobs() {
  return useContext(JobsContext);
}
