import { Avatar, Button, Chip, CircularProgress, FormHelperText, Paper, TextField, Typography } from '@mui/material';
import classes from './EmployerEditor.module.css';
import { useTheme } from '@mui/system';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useEmployers } from 'modules/employers/services/employers.service';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useGigup } from 'modules/shared/services/gigup.service';
import { Industry } from 'modules/shared/services/gigup';
import { Employer } from 'modules/employers/services/employer';


type IFormInputs = {
  companyName: string;
  billingCity: string;
  billingState: string;
  billingStreet1: string;
  billingStreet2: string;
  billingZip: string;
  industry: Industry;
};


const schema = yup.object({
  companyName: yup.string().required('Required'),
  billingCity: yup.string().required('Required'),
  billingState: yup.string().required('Required'),
  billingStreet1: yup.string().required('Required'),
  billingStreet2: yup.string().required('Required'),
  industry: yup.object().required('Required'),
}).required();


export function EmployerEditorScreen() {
  const { employerId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const { uploadImage, loadImage, addEmployer, updateEmployer, getEmployerById } = useEmployers();
  const { allIndustries } = useGigup();
  const [imageUrl, setImageUrl] = useState<string>();
  const [isBusy, setIsBusy] = useState(false);
  const [imageFile, setImageFile] = useState<File>();
  const { register, handleSubmit, control, reset, formState: { errors } } = useForm<IFormInputs>({
    resolver: yupResolver(schema)
  });
  const title = useMemo(() => employerId ? 'Edit Employer' : 'Create Employer', [employerId]);
  const [employer, setEmployer] = useState<Employer>();

  useEffect(() => {
    if (employerId) {
      getEmployerById(employerId).then(setEmployer);
      loadImage(employerId).then(setImageUrl);
    }
  }, [getEmployerById, employerId]);

  useEffect(() => {
    if (employer) {
      const { ref, ...data } = employer; // exclude ref reccurence
      reset(data);
    }
  }, [reset, employer]);

  async function handleImageSelect({ target }: ChangeEvent<HTMLInputElement>) {
    if (target.files?.length) {
      setImageUrl(URL.createObjectURL(target.files[0]));
      setImageFile(target.files[0]);
    }
  }

  async function onSubmit(data: IFormInputs) {
    setIsBusy(true);
    const id = employerId
      ? (await updateEmployer(employerId, data), employerId)
      : (await addEmployer(data)).id;
    if (imageFile) {
      const url = await uploadImage(imageFile, id);
      setImageUrl(url);
    }
      setIsBusy(false);
    navigate(-1);
  }

  return (
    <div className={classes.container} style={{ padding: theme.spacing(4, 0, 2) }}>
      <div className={classes.content}>

        <div className={classes.header}>
          <Typography className='title' variant='h4' sx={{ color: 'mainBackground.contrastText' }}>{title}</Typography>
          { isBusy && <CircularProgress className={classes.progress} size={30}/> }
        </div>

        <Paper
          sx={{ mt: 1, p: 4, display: 'flex', flexDirection: 'column' }}
          component='form'
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            accept='image/*'
            className={classes.input}
            hidden
            multiple
            id='button-file'
            type='file'
            onChange={handleImageSelect}
          />
          <label htmlFor='button-file' className={classes.avatarWrapper}>
            <Avatar className={classes.avatar} src={imageUrl}/>
          </label>

          <TextField
            error={!!errors.companyName}
            helperText={errors.companyName?.message ?? ' '}
            {...register('companyName')}
            InputLabelProps={{ shrink: true }}
            margin='dense'
            fullWidth
            label='Employer Name'
            autoFocus
          />

          <TextField
            error={!!errors.billingCity}
            helperText={errors.billingCity?.message ?? ' '}
            {...register('billingCity')}
            InputLabelProps={{ shrink: true }}
            margin='dense'
            fullWidth
            label='Billing City'
          />

          <TextField
            error={!!errors.billingState}
            helperText={errors.billingState?.message ?? ' '}
            {...register('billingState')}
            InputLabelProps={{ shrink: true }}
            margin='dense'
            fullWidth
            label='Billing State'
          />

          <TextField
            error={!!errors.billingStreet1}
            helperText={errors.billingStreet1?.message ?? ' '}
            {...register('billingStreet1')}
            InputLabelProps={{ shrink: true }}
            margin='dense'
            fullWidth
            label='Billing Street 1'
          />

          <TextField
            error={!!errors.billingStreet2}
            helperText={errors.billingStreet2?.message ?? ' '}
            {...register('billingStreet2')}
            InputLabelProps={{ shrink: true }}
            margin='dense'
            fullWidth
            label='Billing Street 2'
          />

          <Typography variant='subtitle1' color={errors.industry && 'error'}>Select Industry</Typography>
          <div className={classes.industriesBlock}>
            <Controller
              name='industry'
              control={control}
              render={({ field }) => <>{
                allIndustries.map(industry =>
                  <Chip
                    className={classes.industry}
                    label={industry.name}
                    key={industry.uid}
                    variant={errors.industry && 'outlined'}
                    onClick={() => field.onChange(industry)}
                    color={field.value?.uid === industry.uid ? 'primary' : errors.industry ? 'error' : undefined}
                  />
                )
              }</>}
            />
            <FormHelperText sx={{ ml: 2 }} error>{(errors.industry as FieldError)?.message ?? ' '}</FormHelperText> {/* !!! errors.industry type is incorrectly set to Industry while really it is FieldError */}
          </div>

          <Button
            variant='contained'
            type='submit' sx={{ mt: 2, alignSelf: 'flex-end' }}
            className={classes.saveButton}
          >Save</Button>
        </Paper>
      </div>
    </div>
  )
}
