import { useRef } from 'react';
import { AuthProvider } from 'modules/auth/services/auth.service';
import { FirebaseProvider } from 'modules/shared/services/firebase.service';
import { JobsProvider } from 'modules/jobs/services/jobs.service';
import { CandidateProvider } from 'modules/candidate/services/candidate.service';
import { UserProvider } from 'modules/user/services/user.service';
import { GigupProvider } from '../services/gigup.service';
import { EmployersProvider } from 'modules/employers/services/employers.service';


// Providers are applied in their order in array
export function useProviders() {
  return useRef([
    FirebaseProvider,
    GigupProvider,
    AuthProvider,
    JobsProvider,
    EmployersProvider,
    UserProvider,
    CandidateProvider
  ].reduceRight((P2, P1) => ({ children }) => <P1><P2>{children}</P2></P1>)).current;
}