import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import classes from './DataTable.module.css';
import { useMemo } from 'react';
import { DataTableHead } from './DataTableHead';
import { Order, HeadCell } from './interfaces';
import { DataTableToolbar } from './DataTableToolbar';
import { getComparator } from './getComparator.fn';
import { ActionsMenu } from './ActionsMenu';


export type Action<TData> = {
  onAction: (item: TData) => void;
  label: string;
}


type Props<TData, Key extends keyof TData> = {
  selectable?: boolean;
  actions?: Action<TData>[];
  data: TData[];
  headCells: readonly HeadCell<TData, Key>[];
  initialOrderBy: Key;
  keyField: (row: TData) => React.Key;
  onRowClick?: (item: TData) => void;
  title?: string;
  minWidth?: number;
}


export function DataTable<TData, Key extends keyof TData>({ data, initialOrderBy, onRowClick, headCells, keyField, title, selectable, actions, minWidth = 750 }: Props<TData, Key>) {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<Key>(initialOrderBy);
  const [selected, setSelected] = React.useState<readonly React.Key[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: Key,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map(keyField);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_event: React.MouseEvent<unknown>, name: React.Key) => {
    if (onRowClick) {
      const row = data.find(row => keyField(row) === name);
      if (row) onRowClick(row);
    }
    if (!selectable) return;

    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly React.Key[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  function handleAction(action: Action<TData>, row: TData) {
    action.onAction(row);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: React.Key) => selected.indexOf(name) !== -1;

  // Avoid layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const comparator: (item: TData) => string | number | Date = useMemo(() =>
    headCells.find(d => d.id === orderBy)?.sortValue ?? (row => row[orderBy] as unknown as number | string) // cast to unknown to not overburden ts types checking, it is up to developer to provide correct sortable columns
  , [headCells, orderBy]);

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>

        { selectable && <DataTableToolbar numSelected={selected.length} title={title}/> }

        <TableContainer>
          <Table
            sx={{ minWidth }}
            aria-labelledby='tableTitle'
          >
            <DataTableHead
              selectable={selectable}
              hasActions={!!actions}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
              headCells={headCells}
            />
            <TableBody>
              {data.slice().sort(getComparator(order, comparator))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(keyField(row));
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      className={classes.row}
                      onClick={event => handleClick(event, keyField(row))}
                      role='checkbox'
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={keyField(row)}
                      selected={isItemSelected}
                    >
                      { selectable &&
                        <TableCell padding='checkbox'>
                          <Checkbox
                            color='primary'
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                      }

                      {headCells.map((col, i) =>
                        <TableCell
                          component={!i ? 'th' : undefined}
                          padding={ !i && selectable ? 'none' : 'normal' }
                          align={col.numeric ? 'right' : 'left'} key={col.id}
                        >{row[col.id]}</TableCell>
                      )}

                      { actions &&
                        <TableCell padding='checkbox'>
                          <ActionsMenu actions={actions} onAction={action => handleAction(action, row)}/>
                        </TableCell>
                      }
                    </TableRow>
                  );
                })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={headCells.length + 1}/>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
