import * as React from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { getAllIndustries } from './helpers/getAllIndustries.fn';
import { getAllJobtypes } from './helpers/getAllJobtypes.fn';
import { useFirebase } from './firebase.service';
import { Industry, Jobtype } from './gigup';


type GigupContextType = {
  allIndustries: Industry[];
  allJobtypes: Jobtype[];
};


const GigupContext = createContext<GigupContextType>(null!);


export function GigupProvider({ children }: { children: React.ReactNode }) {
  const { firebaseApp } = useFirebase();
  const [allIndustries, setAllIndustries] = useState<Industry[]>([]);
  const [allJobtypes, setAllJobtypes] = useState<Industry[]>([]);

  useEffect(() => {
    getAllIndustries(firebaseApp).then(setAllIndustries);
    getAllJobtypes(firebaseApp).then(setAllJobtypes);
  }, [firebaseApp]);

  const context = useMemo<GigupContextType>(
    () => ({
      allIndustries,
      allJobtypes
    }),
    [allIndustries, allJobtypes]
  );

  return <GigupContext.Provider value={context}>{children}</GigupContext.Provider>;
}


export function useGigup() {
  return useContext(GigupContext);
}
