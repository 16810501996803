import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Job } from 'modules/jobs/services/job';
import { useJobs } from 'modules/jobs/services/jobs.service';
import { ComponentPropsWithoutRef, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import classes from './JobPanel.module.css';
import { Section } from './Section';
import CircularProgress from '@mui/material/CircularProgress';


function JobSection(props: ComponentPropsWithoutRef<typeof Section>) {
  return <Section {...props} className={classes.section}/>
}


export function JobPanel() {
  const { getJobById } = useJobs();
  const { jobId } = useParams<'jobId'>();
  const [job, setJob] = useState<Job>();

  useEffect(() => {
    if (jobId) getJobById(jobId).then(setJob);
  }, [jobId, getJobById]);

  return (
    <div className={classes.container}>
      <div className={classes.panelWrapper}>
        <div className={classes.header}>
          <Typography variant='h6' sx={{color: 'mainBackground.contrastText', ml: 2}}>Opening Information</Typography>
          { !job && <CircularProgress className={classes.progress} size={20}/> }
        </div>
        <Paper className={classes.panel} sx={{alignSelf: 'flex-end'}}>
          {<>
            <JobSection title='Job Title'>{job ? job.title : '--'}</JobSection>
            <JobSection title='Location'>{job ? job.location : '--'}</JobSection>
            <JobSection title='Pay Range'>{job ? `${job.minHourlyWage} - ${job.maxHourlyWage}/hr` : '--'}</JobSection>
            <JobSection title='Description'>{job ? job.description : '--'}</JobSection>
            <JobSection title='Date Posted'>{job ? job.dateCreated.toLocaleDateString('en-US') : '--'}</JobSection>
            <JobSection title='Job Type Selected'>{job?.jobtypes.map(j => j.name).join(', ') ?? '--'}</JobSection>
            <JobSection title='Industries Selected'>{job?.industries.map(i => i.name).join(', ') ?? '--'}</JobSection>
            <JobSection title='Number of Openings'>{job ? job.openings : '--'}</JobSection>
            <JobSection title='Start and End Date'>{
              job ?
                job.firstDay.toLocaleDateString('en-US') +
                ' - ' +
                job.lastDay.toLocaleDateString('en-US')
              : '--'
            }</JobSection>
          </>
          }
        </Paper>
      </div>
    </div>
  )
}