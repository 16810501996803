import { Order } from './interfaces';


function descendingComparator<TData>(
  b: TData,
  a: TData,
  comparator: (item: TData) => string | number | Date
) {
  const aRes = comparator(a);
  const bRes = comparator(b);
  if (bRes < aRes) return -1;
  if (bRes > aRes) return 1;
  return 0;
}


export function getComparator<TData>(order: Order, comparator: (item: TData) => string | number | Date): (
  a: TData, b: TData
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, comparator)
    : (a, b) => -descendingComparator(a, b, comparator);
}
