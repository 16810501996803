import { Avatar, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ComponentPropsWithoutRef, FC, HTMLAttributes } from 'react';
import { Boost } from '../services/candidate';


type Props = ComponentPropsWithoutRef<FC<{
  boost: Boost;
  className?: HTMLAttributes<HTMLDivElement>['className'];
}>>;


export function BoostItem({ boost, className }: Props) {
  return (
    <Grid container direction={'row'} className={className} alignItems='flex-start' justifyContent={'space-around'} sx={{ paddingTop: 1 }} >

      <Avatar alt={boost.name} src={boost.imageURL} sx={{ width: 69, height: 69 }}/>

      <div style={{ width: '212px' }}>
        <Typography variant='subtitle2' sx={{ fontWeight: 'bold' }}>{boost.name}</Typography>

        <Typography
          sx={{ display: 'inline' }}
          component='span'
          variant='body2'
          color='text.primary'
        >
          {boost.name} boosted you for <span style={{ color: '#680edc' }} > {boost.trait}</span>
        </Typography>
        <br />
        <Typography
          sx={{ display: 'inline' }}
          component='span'
          variant='body2'
          color='text.primary'
        >
          "{boost.note}"
        </Typography>
      </div>
    </Grid>
  );
}
