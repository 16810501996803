import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useDefinedAuth } from 'modules/auth/services/auth.service';
import { Job } from 'modules/jobs/services/job';
import { useJobs } from 'modules/jobs/services/jobs.service';
import { User } from 'firebase/auth';
import { getDoc, doc, DocumentReference, Firestore } from 'firebase/firestore/lite';
import { Profile } from './user';
import { useFirebase } from 'modules/shared/services/firebase.service';


export type UserContextType = {
  profile?: Profile;
  isAdmin: boolean;
  isReady: boolean;
  getJobs: () => Promise<Job[] | undefined>;
};


export type UserDefinedContextType = Omit<UserContextType, 'getJobs'> & {
  getJobs: () => Promise<Job[]>
};


export const UserContext = createContext<UserContextType | null>(null);


async function getProfile(db: Firestore, user: User) {
  const docData = await getDoc(doc(db, 'guEmployerUsers/' + user.uid) as DocumentReference<Profile>);
  return docData.data()!;
}


export function UserProvider({ children }: { children: React.ReactNode }) {
  const { db } = useFirebase();
  const { user } = useDefinedAuth();
  const { getEmployerJobs } = useJobs();
  const [profile, setProfile] = useState<Profile>();

  useEffect(() => {
    user ? getProfile(db, user).then(setProfile) : setProfile(undefined);
  }, [user]);

  const context = useMemo<UserContextType>(() => ({
    profile,
    isReady: !!profile,
    isAdmin: profile?.role === 'admin',
    getJobs: async () => getEmployerJobs(profile!.employer.id)
  }), [db, profile]);

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>;
}


export function useUser() {
  return useContext(UserContext);
}


export function useDefinedUser() {
  return useContext(UserContext) as UserDefinedContextType;
}
