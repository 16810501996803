import { Navigation } from 'modules/shared/navigation';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useMemo } from 'react';
import { useProviders } from 'modules/shared/hooks/useProviders';
import { StyledEngineProvider } from '@mui/material/styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';


declare module '@mui/material/styles/createPalette' {
  interface Palette {
    mainBackground: {
      color1: React.CSSProperties['color'];
      color2: React.CSSProperties['color'];
      contrastText: React.CSSProperties['color'];
    },
  }
  interface PaletteOptions {
    mainBackground: {
      color1: React.CSSProperties['color'];
      color2: React.CSSProperties['color'];
      contrastText: React.CSSProperties['color'];
    },
  }
}

// // example of declaring top level `mainBackground` prop in theme
// declare module '@mui/material/styles' {
//   interface Theme {
//     mainBackground: {
//       color1: React.CSSProperties['color'];
//       color2: React.CSSProperties['color'];
//     },
//   }
//   // allow configuration using `createTheme`
//   interface ThemeOptions {
//     mainBackground?: {
//       color1: React.CSSProperties['color'];
//       color2: React.CSSProperties['color'];
//     },
//   }
// }


function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const Providers = useProviders();

  const theme = useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: ['Red Hat Text', 'Roboto', 'Helvetica', 'Arial'].join(',')
        },
        components: {
          MuiAppBar: {
            styleOverrides: {
              root: {
                background: '#363740'
              },
            },
          },
        },
        palette: {
          mainBackground: {
            color1: '#54586b',
            color2: '#989eb0',
            contrastText: '#ffffff'
          },
            // mode: prefersDarkMode ? 'dark' : 'light',
          primary: {
            main: '#680edc',
          },
          secondary: {
            main: '#1554f0',
          },
        },
      }),
    [prefersDarkMode],
  );

  return(
    <ThemeProvider theme={theme}>
      <Providers>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Navigation/>
          </LocalizationProvider>
        </StyledEngineProvider>
      </Providers>
    </ThemeProvider>
  );
}

export default App;
