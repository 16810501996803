import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import { Link as RouteLink, useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { useAuth } from '../services/auth.service';
import classes from './auth.module.css';


function Copyright(props: any) {
  return (
    <Typography variant='body2' color='text.secondary' align='center' {...props}>
      Copyright ©&nbsp;
      <Link color='inherit' href='https://gigup.me/'>
        GigUp.me
      </Link>
      &nbsp;{new Date().getFullYear()}.
    </Typography>
  );
}


export function ForgotPasswordScreen() {
  const { resetPassword } = useAuth();
  const navigate = useNavigate();
  const [message, setMessage] = React.useState('');
  const img = require('modules/shared/images/Backdrop.png');
  const logo = require('modules/shared/images/giguplogo.png');
  const backdrop = 'url('+ img + ')';

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // // eslint-disable-next-line no-console
    // console.log({
    //   email: data.get('email'),
    //   password: data.get('password'),
    // });
    await resetPassword(data.get('email')!.toString());
    setMessage('Check your inbox for futher instructions.');
    // todo: make sure that login is successfull
    // navigate('/');
  };

  return (
    <Grid container component='main' sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: backdrop,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

          <img alt="logo" src={logo} className={classes.logo}/>
          <Typography component='h1' variant='h5'>
            Forgot Your Password
          </Typography>
          <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <Typography component='div' variant='body1'>
            To reset your password, enter your email address. We will send you a reset password link.
              {/* <ul>
                <li>Usernames are in the form of an email address.</li>
                <li>Passwords are case sensitive. </li>
              </ul> */}
              {/* <Typography className={classes.subtitle_b}>
              To reset your password, enter your email address. We will send you a reset password link.
              </Typography> */}
            </Typography>
            <TextField
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              autoComplete='email'
              autoFocus
            />
            {message && <Alert severity="success">{message}</Alert>}
            
            <Grid container justifyContent={'center'}>
            <Button className={classes.form_button}
              type='submit'
              onClick={() => navigate('/')}
              variant='outlined'
              sx={{ mt: 3, mb: 2 }}
            >
              Cancel
            </Button>
            <Button className={classes.form_button}
              type='submit'
              
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
            >
              Continue
            </Button>

            </Grid>
            <Typography variant='body2'>
            Back to&nbsp;
              <Link component={RouteLink} to='/' variant='body2'>
                Sign In
              </Link>
            </Typography>

            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}