import { BaseEmployersScreen } from './BaseEmployersScreen';
import { useDefinedUser } from 'modules/user/services/user.service';
import { EmployersAdminScreen } from './EmployersScreen.admin';
import { useTheme } from '@mui/system';
import classes from './EmployersScreen.module.css';


export function EmployersScreen() {
  const { isAdmin } = useDefinedUser();
  const theme = useTheme();

  return (
    <div className={classes.container} style={{padding: theme.spacing(4, 0, 2)}}>
      {isAdmin ? <EmployersAdminScreen/> : <BaseEmployersScreen/>}
    </div>
  );
}
