import { Candidate } from 'modules/candidate/services/candidate';
import { useJobs } from 'modules/jobs/services/jobs.service';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BaseJobCandidatesScreen } from './BaseJobCandidatesScreen';


export function JobCandidatesEmployerScreen() {
  const { jobId } = useParams<'jobId'>();
  const { getMatchedCandidates } = useJobs();
  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (jobId) getMatchedCandidates(jobId).then(setCandidates);
  }, [getMatchedCandidates, jobId]);

  function handleCandidateClick(candidate: Candidate) {
    navigate(`/jobs/${jobId}/candidates/${candidate.uid}`);
  }

  return (<>
    <BaseJobCandidatesScreen candidates={candidates} onClick={handleCandidateClick}/>
  </>);
}
