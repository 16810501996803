import * as React from 'react';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
import { Order, HeadCell } from './interfaces';


type Props<TData, Key extends keyof TData> = {
  selectable?: boolean;
  hasActions?: boolean;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: Key) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: Key;
  rowCount: number;
  headCells: readonly HeadCell<TData, Key>[]; // header data
};


export function DataTableHead<TData, Key extends keyof TData>({ onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells, selectable, hasActions }: Props<TData, Key>) {
  const createSortHandler =
    (property: Key) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead sx={{backgroundColor: 'primary.main'}}>
      <TableRow>
        { selectable &&
          <TableCell padding='checkbox'>
            <Checkbox
              color='primary'
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all',
              }}
            />
          </TableCell>
        }
        {headCells.map(headCell => {
          const content = <>
            {headCell.label}
            {orderBy === headCell.id ? (
              <Box component='span' sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
          </>;

          return (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{color: 'primary.contrastText'}}
            >
              {headCell.sortable
                ? <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                    sx={[{
                      '&.Mui-active, &:hover, & .MuiSvgIcon-root.MuiTableSortLabel-icon': { color: 'primary.contrastText' },
                    }]}
                  >{content}</TableSortLabel>
                : content
              }
            </TableCell>
          )
        })}
        { hasActions && <TableCell/> }
      </TableRow>
    </TableHead>
  );
}
