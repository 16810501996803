import { CollectionReference, query, where, getDocs, DocumentData, FieldPath } from '@firebase/firestore/lite';
import { chunks } from './array.chunks.fn';


export async function getDocsIn<T = DocumentData>(collection: CollectionReference<T>, fieldPath: string | FieldPath, values: unknown[]) {
  const batches = chunks(values, 10); // firestore limits values length to 10

  const res = await Promise.all(batches.map(async values => {
    const q = query(collection, where(fieldPath, 'in', values));
    return (await getDocs(q)).docs;
  }));
  return res.flat();
}
