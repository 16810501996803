import Typography from "@mui/material/Typography";
import { ComponentPropsWithoutRef, FC, HTMLAttributes } from "react";

type Props = ComponentPropsWithoutRef<FC<{
  title: string;
  className?: HTMLAttributes<HTMLDivElement>['className'];
}>>;


export function Section({ title, className, children }: Props) {
  return (
    <div className={className}>
      <Typography variant='subtitle2' sx={{fontWeight: 'bold'}}>{title}</Typography>
      <Typography variant='body2'>{children}</Typography>
    </div>
  );
}
