import {useState, useMemo} from 'react';
import { useNavigate } from 'react-router-dom';
import { Action } from 'modules/jobs/components/DataTable';
import { Link } from '@mui/material';
import classes from './CurrentJobsScreen.admin.module.css';
import { Job } from 'modules/jobs/services/job';
import { useJobs } from 'modules/jobs/services/jobs.service';
import { BaseCurrentJobsScreen, GridRow } from './BaseCurrentJobsScreen';


export function CurrentJobsAdminScreen() {
  const { deleteJob } = useJobs();
  const [jobs, setJobs] = useState<Job[]>();
  const navigate = useNavigate();

  const actions: Action<GridRow>[] = useMemo(() => [
    { label: 'edit', onAction: ({ raw }) => navigate(raw.ref.id + '/edit') },
    { label: 'delete', onAction: ({ raw }) => {
      deleteJob(raw.ref.id);
      setJobs(jobs?.filter(job => job.ref.id !== raw.ref.id));
    }},
  ], [navigate, deleteJob, jobs]);

  function handleAddJob() {
    navigate('add');
  }

  return(<>
    <BaseCurrentJobsScreen actions={actions}/>

    <Link
      component='button'
      color='mainBackground.contrastText'
      variant='body1'
      underline='none'
      className={classes.addButton}
      onClick={handleAddJob}
    >+ Create New Job</Link>
  </>)
}
