import { useCallback, useEffect, useMemo, useState } from 'react';
import { Avatar, Grid, CardHeader, IconButton } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Chip from '@mui/material/Chip';
import { useNavigate, useParams } from 'react-router-dom';
import { useCandidate } from 'modules/candidate/services/candidate.service';
import classes from './CandidateScreen.module.css';
import { BoostItem } from 'modules/candidate/components/BoostItem';
import { getAge } from 'modules/candidate/services/helpers/getAge.fn';
import { Industry, Jobtype } from 'modules/shared/services/gigup';
import CloseIcon from '@mui/icons-material/Close';


const defaultAvailabilities = ['', '', '', '', '', '', ''];
const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];


export function CandidateScreen() {
  const { candidateId } = useParams();
  const navigate = useNavigate();
  const { boosts, getCandidate, loadAvatar, getCandidateAvailabilities, getBoosts, getBoostsTraits } = useCandidate();

  // candidate fields
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [age, setAge] = useState('');
  const [about, setAbout] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [desiredWage, setDesiredWage] = useState('');
  const [maxCommuteDistance, setMaxCommuteDist] = useState('');
  const [isOpenToNegotiateWage, setNegotiateWage] = useState('');
  const [isOpenToTipbased, setTipBased] = useState('');
  const [preferredIndustries, setPreferredIndustries] = useState<Industry[]>([]);
  const [preferredJobTypes, setPreferredJobTypes] = useState<Jobtype[]>([]);
  const [firstDate, setFirstDate] = useState('');
  const [lastDate, setLastDate] = useState('');
  const [termAvailability, setTermAvailability] = useState('');
  const [scheduleHasFlexibility, setScheduleFlex] = useState('');
  const [availabilities, setAvailabilities] = useState(defaultAvailabilities);
  const [imageURL, setImageURL] = useState('');

  useEffect(() => {
    init();
  }, []);

  // load candidate from db and set/format page fields
  const init = useCallback(async () => {
    const candidate = candidateId ? await getCandidate(candidateId) : undefined;

    setFirstname(candidate?.firstName ?? '');
    setLastname(candidate?.lastName ?? '');
    setAge(candidate?.birthday ? '' + getAge(candidate?.birthday) : '');
    setPhoneNumber(candidate?.phoneNumber ?? '');
    setAbout(candidate?.about ?? '');
    setDesiredWage('' + candidate?.desiredWage ?? '')
    setNegotiateWage(candidate?.isOpenToNegotiateWage ? 'Yes' : 'No');
    setMaxCommuteDist('' + candidate?.commuteDistance ?? '');
    setTipBased(candidate?.isWageWithTips ? 'Yes' : 'No');
    setPreferredIndustries(candidate?.preferredIndustries ?? []);
    setPreferredJobTypes(candidate?.preferredJobtypes ?? []);
    setAvailabilities(getCandidateAvailabilities() ?? defaultAvailabilities);
    await getBoosts();
    setFirstDate(candidate?.firstDay.toDate().toLocaleDateString('en-US') ?? '');
    setLastDate(candidate?.lastDay?.toDate().toLocaleDateString('en-US') ?? '');
    setScheduleFlex(candidate?.isScheduleFlexible ? 'Yes' : 'No');
    loadAvatar().then(setImageURL);

    if (candidate?.isAvailableShortTerm)
      setTermAvailability('Short Term');
    else {
      setLastDate('Available long-term');
      setTermAvailability('Long Term');
    }
  }, []);

  const traits = useMemo(() => {
    const traits = getBoostsTraits() ?? [];
    return traits
      .filter(d => d.count)
      .sort((a, b) => a.count < b.count ? 1 : -1)
      .slice(0, 3);
  }, [boosts]);

  return (
    <div className={classes.container}>
      <Card>
        <CardHeader action={
          <IconButton aria-label='close' onClick={() => navigate(-1)}><CloseIcon/></IconButton>
        }/>
        <CardContent className={classes.card_content}>
          <Grid container direction='column' alignItems='center'>
            <Avatar sx={{ width: 205, height: 205 }} src={imageURL}></Avatar>
            <Typography className={classes.candidatename} component='div'>
              {firstname} {lastname}, {age}
            </Typography>
            <Typography className={classes.subtitle_b} variant='h6' component='div'>{phoneNumber}</Typography>

            <Grid container alignItems='start' justifyContent='space-between'>
              <Grid item xs={2} sm={4} md={4}>
                <Grid container direction={'column'} alignItems={'start'}>
                  <div className={classes.column_item}>
                    <Typography className={classes.subtitle_b} variant='h6' component='div'>
                      About
                    </Typography>
                    <Typography variant='body2'>
                      {about}
                    </Typography>
                  </div>
                  <div className={classes.column_item}>
                    <Typography className={classes.subtitle_b} variant='h6' component='div'>
                      Desired Wage
                    </Typography>
                    <Typography variant='body2'>
                      ${desiredWage}
                    </Typography>
                  </div>
                  <div className={classes.column_item}>
                    <Typography className={classes.subtitle_b} variant='h6' component='div'>
                      Open to Negociate?
                    </Typography>
                    <Typography variant='body2'>
                      {isOpenToNegotiateWage}
                    </Typography>
                  </div>
                  <div className={classes.column_item}>
                    <Typography className={classes.subtitle_b} variant='h6' component='div'>
                      Open to Tip-Based Compensation
                    </Typography>
                    <Typography variant='body2'>
                      {isOpenToTipbased}
                    </Typography>
                  </div>
                  <div className={classes.column_item}>
                    <Typography className={classes.subtitle_b} variant='h6' component='div'>
                      Maximum Commute Distance
                    </Typography>
                    <Typography variant='body2'>
                      {maxCommuteDistance} miles
                    </Typography>
                  </div>
                  <div className={classes.column_item}>
                    <Typography className={classes.subtitle_b} variant='h6' component='div'>
                      Preferred Industries
                    </Typography>
                    <Typography variant='body2'>
                      {preferredIndustries}
                    </Typography>
                  </div>
                  <div className={classes.column_item}>
                    <Typography className={classes.subtitle_b} variant='h6' component='div'>
                      Preferred Job Types
                    </Typography>
                    <Typography variant='body2'>
                      {preferredJobTypes}
                    </Typography>
                  </div>
                </Grid>
              </Grid>

              <Grid item xs={2} sm={4} md={4} justifyContent='space-around' >
                <Grid container direction={'column'}>
                  <div className={classes.column_item}>
                    <Typography className={classes.subtitle_b} variant='h6' component='div'>
                      First Date/Last Date Available
                    </Typography>
                    <Typography variant='body2'>
                      {firstDate}/ {lastDate}
                    </Typography>
                  </div>
                  <div className={classes.column_item}>
                    <Typography className={classes.subtitle_b} variant='h6' component='div'>
                      Short/Long Term
                    </Typography>
                    <Typography variant='body2'>
                      {termAvailability}
                    </Typography>
                  </div>
                  <div className={classes.column_item}>
                    <Typography className={classes.subtitle_b} variant='h6' component='div'>
                      Schedule Has Flexibility?
                    </Typography>
                    <Typography variant='body2'>
                      {scheduleHasFlexibility}
                    </Typography>
                  </div>
                  <div className={classes.column_item}>
                    <Typography className={classes.subtitle_b} variant='h6' component='div'>
                      Availability
                    </Typography>
                  </div>

                  {weekDays.map((name, i) =>
                    <div key={name} className={classes.column_item}>
                      <Typography variant='body2' component='div'>
                        {name}s:
                      </Typography>
                      <Typography variant='body2'>
                        {availabilities[i]}
                      </Typography>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </CardContent>
        <CardContent className={classes.card_content_traits}>
          <Grid container direction={'column'} item xs={2} sm={4} md={4} justifyContent='space-around' >
            <Typography variant='h6' component='div'>
              Boosts
            </Typography>
            <Typography variant='body2'>
              Top Traits
            </Typography>
            <Grid container direction={'column'}>
              <List>
                { traits?.map((d, idx) =>
                  <ListItem key={idx}> <Chip className={classes.trait_count} label={d.count} color='primary' variant='outlined'/> {d.name}</ListItem>
                )}
              </List>
            </Grid>
          </Grid>
        </CardContent>

        <CardContent className={classes.card_content_boosts}>
          <Grid container direction='row' justifyContent='start'>
            { boosts?.map((d, idx) =>
              <BoostItem key={idx} boost={d} className={classes.boost_item}/>
            )}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}