import { BaseJobCandidatesScreen, GridRow } from './BaseJobCandidatesScreen';
import { useNavigate, useParams } from 'react-router-dom';
import { Candidate } from 'modules/candidate/services/candidate';
import { useJobs } from 'modules/jobs/services/jobs.service';
import { useState, useEffect, useMemo } from 'react';
import { Action } from 'modules/jobs/components/DataTable';


export function JobCandidatesAdminScreen() {
  const { jobId } = useParams<'jobId'>();
  const { getAllCandidates } = useJobs();
  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const navigate = useNavigate();

  const actions: Action<GridRow>[] = useMemo(() => [
    { label: 'edit', onAction: ({ raw: candidate }) => navigate(`/jobs/${jobId}/candidates/${candidate.uid}`) },
  ], [navigate]);

  useEffect(() => {
    getAllCandidates().then(setCandidates);
  }, [getAllCandidates]);

  function handleCandidateClick(candidate: Candidate) {
    console.log('toggle', candidate.firstName)
  }

  return (
    <BaseJobCandidatesScreen
      actions={actions}
      candidates={candidates}
      onClick={handleCandidateClick}
    />
  );
}
