import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Action } from './DataTable';
import MoreVertIcon from '@mui/icons-material/MoreVert';


type Props<TData> = {
  actions: Action<TData>[];
  onAction: (action: Action<TData>) => void;
}

export function ActionsMenu<TData>({ actions, onAction }: Props<TData>) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (e: React.MouseEvent<HTMLElement>, action?: Action<TData>) => {
    e.stopPropagation();
    setAnchorEl(null);
    if (action) onAction(action);
  };

  return (
    <Box sx={{ flexGrow: 0}}>
      <Tooltip title='actions'>
        <IconButton onClick={handleOpenMenu}>
          <MoreVertIcon/>
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id='menu-appbar'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={e => handleCloseMenu(e as React.MouseEvent<HTMLElement>)}
      >
        {actions.map(action => (
          <MenuItem key={action.label} onClick={e => handleCloseMenu(e, action)}>
            <Typography textAlign='center'>{action.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}