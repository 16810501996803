import * as React from 'react';
import { createContext, useContext, useMemo } from 'react';
import { useFirebase } from 'modules/shared/services/firebase.service';
import { AddEmployerDto, AddFormEmployerDto, Employer, EmployerDto } from 'modules/employers/services/employer';
import { addDoc, collection, CollectionReference, deleteDoc, doc, DocumentReference, Firestore, getDoc, getDocs, getFirestore, query, setDoc } from 'firebase/firestore/lite';
import { FirebaseApp } from 'firebase/app';
import { Industry } from 'modules/shared/services/gigup';
import { useGigup } from 'modules/shared/services/gigup.service';
import { useDefinedAuth } from 'modules/auth/services/auth.service';
import { deleteObject, getStorage, ref } from 'firebase/storage';
import { loadImage } from '../../shared/helpers/loadImage.fn';
import { uploadImage } from 'modules/shared/helpers/uploadImage.fn';


type EmployersContextType = {
  getEmployers: () => Promise<Employer[]>;
  getEmployerById: (id: string) => Promise<Employer | undefined>;
  addEmployer: (employer: AddFormEmployerDto) => Promise<DocumentReference<AddEmployerDto>>;
  updateEmployer: (id: string, employer: AddFormEmployerDto) => Promise<void>;
  deleteEmployer: (id: string) => Promise<void>;
  uploadImage: (file: File, id: string) => Promise<string>;
  loadImage: (id: string) => Promise<string>;
};


const EmployersContext = createContext<EmployersContextType>(null!);


async function getEmployers(db: Firestore, allIndustries: Industry[]): Promise<Employer[]> {
  const q = query(
    collection(db, 'guEmployers') as CollectionReference<EmployerDto>,
  );

  return (await getDocs(q)).docs.map(d => {
    const data = d.data();
    return { ...data, industry: allIndustries.find(i => i.uid === data.industry.id)!, ref: d.ref }
  });
}


async function getEmployerById(db: Firestore, allIndustries: Industry[], id: string): Promise<Employer | undefined> {
  const ref = doc(collection(db, 'guEmployers') as CollectionReference<EmployerDto>, id);
  const employer = (await getDoc(ref)).data();
  return employer && { ...employer, industry: allIndustries.find(i => i.uid === employer.industry.id)!, ref };
}


async function addEmployer(db: Firestore, employer: AddFormEmployerDto): Promise<DocumentReference<EmployerDto>> {
  const { industry, ...dto } = employer;
  const industryRef = doc(collection(db, 'guRefIndustries') as CollectionReference<Industry>, industry.uid);
  return addDoc(collection(db, 'guEmployers') as CollectionReference<EmployerDto>,
    { ...dto, industry: industryRef } as EmployerDto
  );
}


async function updateEmployer(db: Firestore, id: string, employer: AddFormEmployerDto) {
  const { industry, ...dto } = employer;
  const industryRef = doc(collection(db, 'guRefIndustries') as CollectionReference<Industry>, industry.uid);
  const ref = doc(collection(db, 'guEmployers') as CollectionReference<EmployerDto>, id);
  await setDoc(ref, { ...dto, industry: industryRef }, { merge: true });
}


async function deleteEmployer(app: FirebaseApp, id: string) {
  const db = getFirestore(app);
  await deleteDoc(doc(db, 'guEmployers', id)); // delete employer doc
  console.log('delete image', id);
  await deleteObject(ref(getStorage(app), 'images/' + id)); // delete employer image
}


export function EmployersProvider({ children }: { children: React.ReactNode }) {
  const { firebaseApp, db } = useFirebase();
  const { allIndustries } = useGigup();
  const { user } = useDefinedAuth();

  const context = useMemo<EmployersContextType>(() => ({
    getEmployers: () => getEmployers(db, allIndustries),
    getEmployerById: (id) => getEmployerById(db, allIndustries, id),
    addEmployer: (employer) => addEmployer(db, employer),
    updateEmployer: (id, employer) => updateEmployer(db, id, employer),
    deleteEmployer: (id) => deleteEmployer(firebaseApp, id),
    uploadImage: (file, id) => uploadImage(firebaseApp, id, file),
    loadImage: (id) => loadImage(firebaseApp, id),
  }), [firebaseApp, allIndustries, user]);

  return <EmployersContext.Provider value={context}>{children}</EmployersContext.Provider>;
}


export function useEmployers() {
  return useContext(EmployersContext);
}
