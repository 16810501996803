import { useDefinedUser } from 'modules/user/services/user.service';
import { useTheme } from '@mui/system';
import classes from './BaseJobCandidatesScreen.module.css';
import { JobCandidatesAdminScreen } from './JobCandidatesScreen.admin';
import { JobCandidatesEmployerScreen } from './JobCandidatesScreen.employer';


export function JobCandidatesScreen() {
  const { isAdmin, isReady } = useDefinedUser();
  const theme = useTheme();

  return (
    <div className={classes.container} style={{padding: theme.spacing(4, 0, 2)}}>
      { isReady && (isAdmin
        ? <JobCandidatesAdminScreen/>
        : <JobCandidatesEmployerScreen/>)
      }
    </div>
  );
}
