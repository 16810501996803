import { FirebaseApp } from 'firebase/app';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';


export async function uploadImage(app: FirebaseApp, name: string, file: File) {
  const fileRef = ref(getStorage(app), 'images/' + name);
  await uploadBytes(fileRef, file);

  return await getDownloadURL(fileRef);
}
