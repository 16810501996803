import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
// import * as serviceWorker from './serviceWorker';
import './index.css';
import CssBaseline from '@mui/material/CssBaseline';
import '@fontsource/red-hat-text';
import App from './App';


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <CssBaseline enableColorScheme/>
      <App/>
    </BrowserRouter>
  </React.StrictMode>,
document.getElementById('root'));
