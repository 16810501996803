import { Timestamp } from '@firebase/firestore/lite';
import { format } from 'date-fns';
import { TimeRange } from '../candidate';


export function formattedTimeRange(range?: TimeRange<Timestamp>) {
  if (!range) return '';
  const start = range.start ? format(range.start.toDate(), 'hh:mm a') : 'N/A';
  const end = range.end ? format(range.end.toDate(), 'hh:mm a') : 'N/A';
  return start + ' - ' + end;
}
